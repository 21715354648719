<template>
  <cadastro-base
    :salvar="salvar"
    :cancelar="cancelar"
  >
    <template slot="conteudo">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Servidor de saída de e-mails"
            label-for="servidorInput"
          >
            <validation-provider
              #default="{ errors }"
              :rules="{required: true}"
              name="Servidor"
              auto-focus
            >
              <b-form-input
                id="servidorInput"
                v-model="form.servidor"
                placeholder="Informe o nome do servidor de email"
                type="text"
                :state="errors.length > 0 ? false : null "
                @input="LimparMensagemEmailSaidaErro"
              />
              <small class="text-danger">{{ errors[0] || emailSaidaMensagemErro }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Porta"
            label-for="portaInput"
          >
            <validation-provider
              #default="{ errors }"
              :rules="{required: true}"
              name="Porta"
            >
              <b-form-input
                id="portaInput"
                v-model="form.porta"
                type="number"
                :state="errors.length > 0 ? false : null "
                @input="ValidarCriptografia"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Criptografia"
            label-for="criptInput"
          >
            <validation-provider
              #default="{ errors }"
              :rules="{required: true}"
              name="Servidor de email"
              auto-focus
            >
              <b-select
                id="criptInput"
                v-model="form.criptografia"
                :state="errors.length > 0 ? false : null "
                :options="criptografiaOptions"
                @input="ValidarCriptografia(), LimparMensagemCriptografiaErro()"
              />
              <small class="text-danger">{{ errors[0] || criptografiaMensagemErro }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="E-mail remetente padrão"
            label-for="emailInput"
          >
            <validation-provider
              #default="{ errors }"
              :rules="{required: true}"
              name="E-mail"
            >
              <b-form-input
                id="emailInput"
                v-model="form.emailRemetente"
                placeholder="Informe o e-mail do remetente"
                type="email"
                :state="errors.length > 0 ? false : null "
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Senha"
            label-for="senhaInput"
          >
            <validation-provider
              #default="{ errors }"
              :rules="{required: true}"
              name="Senha"
            >
              <senha-input
                :id="'senhaInput'"
                v-model="form.senha"
                :state="errors.length > 0 ? false : null "
                auto-complete-senha="new-password"
                type="password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Descrição remetente"
            label-for="descRemetenteInput"
          >
            <b-form-input
              id="descRemetenteInput"
              v-model="form.descRemetente"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label-for="assinaturaInput"
            label="Assinatura do e-mail"
          >
            <upload-e-visualizacao
              id="assinaturaInput"
              ref="upload"
              :value="form.assinatura"
              :titulo="'assinatura'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <loading
        class="loading"
        :show-cancel-button="false"
        :is-loading="isLoading"
        :titulo-carregamento="'Aguarde!'"
        :descricao="'Verificando SMTP'"
      />
    </template>
  </cadastro-base>
</template>
<script>
import { ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import mixins from '@pilar/mixins/upload'
import api from './shared/services/api'

export default {
  name: 'EmailConfiguracao',
  components: {
    ValidationProvider,
    SenhaInput: () => import('@pilar/components/senha-input/SenhaInput.vue'),
    UploadEVisualizacao: () => import('@pilar/plugins/upload-e-visualizacao/UploadEVisualizacao.vue'),
    CadastroBase: () => import('@pilar/components/cadastro-base/CadastroBase.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  mixins: [mixins],
  data() {
    return {
      required,
      form: {},
      criptografiaOptions: [{ text: 'Nenhum', value: 'none', disabled: false }, { text: 'SSL', value: 'ssl', disabled: false }, { text: 'TSL', value: 'tsl', disabled: false }],
      isLoading: false,
      emailSaidaMensagemErro: '',
      criptografiaMensagemErro: '',
    }
  },
  created() {
    this.carregar()
  },
  methods: {
    getDefaultValue() {
      return {
        id: 0,
        servidor: '',
        porta: '',
        criptografia: 'none',
        emailRemetente: '',
        senha: '',
        descRemetente: '',
      }
    },
    carregar() {
      api.get()
        .then(payload => {
          this.form = payload.data
          if (!this.form) {
            this.form = this.getDefaultValue()
          }
        })
    },
    salvar() {
      this.isLoading = true
      this.LimparMensagemEmailSaidaErro()
      this.LimparMensagemCriptografiaErro()
      return new Promise((resolve, reject) => {
        const form = { ...this.form }
        form.assinatura = null
        const formData = this.$refs.upload.getFormDataBody(form)
        api.update(formData)
          .then(payload => {
            this.form.id = payload.data
            resolve('Configuração de e-mail cadastrada com sucesso')
            this.isLoading = false
          })
          .catch(err => {
            if (err.response.data.message === 'Configuração de SMTP inválido: Servidor de saída de e-mails não é valido') {
              this.emailSaidaMensagemErro = 'Preencha o campo corretamente'
            } else if (err.response.data.message === 'Configuração de SMTP inválido: Criptografia inválida') {
              this.criptografiaMensagemErro = 'Preencha o campo corretamente'
            }
            reject(new Error(err.response.data.message))
            this.isLoading = false
          })
      })
    },
    cancelar() {
      this.$router.push({ name: 'dashboard-analitico' })
    },
    ValidarCriptografia() {
      if (this.form.porta === '587' || this.form.porta === 587) {
        this.criptografiaOptions.find(opt => opt.value === 'ssl').disabled = true
        if (this.form.criptografia === 'ssl') {
          this.form.criptografia = this.criptografiaOptions[0].value
        }
      } else {
        this.criptografiaOptions.find(opt => opt.value === 'ssl').disabled = false
      }

      if (this.form.porta === '465' || this.form.porta === 465) {
        this.criptografiaOptions.find(opt => opt.value === 'tsl').disabled = true
        if (this.form.criptografia === 'tsl') {
          this.form.criptografia = this.criptografiaOptions[0].value
        }
      } else {
        this.criptografiaOptions.find(opt => opt.value === 'tsl').disabled = false
      }
    },
    LimparMensagemEmailSaidaErro() {
      this.emailSaidaMensagemErro = ''
    },
    LimparMensagemCriptografiaErro() {
      this.criptografiaMensagemErro = ''
    },
  },

}
</script>
