var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('cadastro-base',{attrs:{"salvar":_vm.salvar,"cancelar":_vm.cancelar}},[_c('template',{slot:"conteudo"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Servidor de saída de e-mails","label-for":"servidorInput"}},[_c('validation-provider',{attrs:{"rules":{required: true},"name":"Servidor","auto-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"servidorInput","placeholder":"Informe o nome do servidor de email","type":"text","state":errors.length > 0 ? false : null},on:{"input":_vm.LimparMensagemEmailSaidaErro},model:{value:(_vm.form.servidor),callback:function ($$v) {_vm.$set(_vm.form, "servidor", $$v)},expression:"form.servidor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.emailSaidaMensagemErro))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Porta","label-for":"portaInput"}},[_c('validation-provider',{attrs:{"rules":{required: true},"name":"Porta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"portaInput","type":"number","state":errors.length > 0 ? false : null},on:{"input":_vm.ValidarCriptografia},model:{value:(_vm.form.porta),callback:function ($$v) {_vm.$set(_vm.form, "porta", $$v)},expression:"form.porta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Criptografia","label-for":"criptInput"}},[_c('validation-provider',{attrs:{"rules":{required: true},"name":"Servidor de email","auto-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-select',{attrs:{"id":"criptInput","state":errors.length > 0 ? false : null,"options":_vm.criptografiaOptions},on:{"input":function($event){_vm.ValidarCriptografia(), _vm.LimparMensagemCriptografiaErro()}},model:{value:(_vm.form.criptografia),callback:function ($$v) {_vm.$set(_vm.form, "criptografia", $$v)},expression:"form.criptografia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.criptografiaMensagemErro))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"E-mail remetente padrão","label-for":"emailInput"}},[_c('validation-provider',{attrs:{"rules":{required: true},"name":"E-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"emailInput","placeholder":"Informe o e-mail do remetente","type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.form.emailRemetente),callback:function ($$v) {_vm.$set(_vm.form, "emailRemetente", $$v)},expression:"form.emailRemetente"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Senha","label-for":"senhaInput"}},[_c('validation-provider',{attrs:{"rules":{required: true},"name":"Senha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('senha-input',{attrs:{"id":'senhaInput',"state":errors.length > 0 ? false : null,"auto-complete-senha":"new-password","type":"password"},model:{value:(_vm.form.senha),callback:function ($$v) {_vm.$set(_vm.form, "senha", $$v)},expression:"form.senha"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Descrição remetente","label-for":"descRemetenteInput"}},[_c('b-form-input',{attrs:{"id":"descRemetenteInput","type":"text"},model:{value:(_vm.form.descRemetente),callback:function ($$v) {_vm.$set(_vm.form, "descRemetente", $$v)},expression:"form.descRemetente"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"assinaturaInput","label":"Assinatura do e-mail"}},[_c('upload-e-visualizacao',{ref:"upload",attrs:{"id":"assinaturaInput","value":_vm.form.assinatura,"titulo":'assinatura'}})],1)],1)],1),_c('loading',{staticClass:"loading",attrs:{"show-cancel-button":false,"is-loading":_vm.isLoading,"titulo-carregamento":'Aguarde!',"descricao":'Verificando SMTP'}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }