import axios from '@axios'

const PREFIX = '/email-configuracao'

export default {

  get() {
    return axios.get(`${PREFIX}`)
  },

  update(config) {
    return axios.post(`${PREFIX}`, config)
  },

  validaSMTP(dados) {
    return axios.post(`${PREFIX}/valida-smtp`, dados)
  },
}
